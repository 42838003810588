import { createRouter, createWebHistory } from 'vue-router'
import { routes } from "./router";
import { test } from '@/config/index';
import ajax from '@/ajax/index';

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes
})
var date = new Date()
var m = date.getMonth() + 1
var d = date.getDate()
var now = date.getFullYear() + "/" + (m < 10 ? "0" + m : m) + "/" + (d < 10 ? "0" + d : d)

if (test) {} else {
    router.beforeEach((to) => {
        const user = JSON.parse(now == localStorage.date ? (localStorage.user || "{}") : "{}")
        const url_obj = ajax.get_url_argument_obj()
        if (!user.account && url_obj.loginnum) {
            url_obj.ID = url_obj.loginnum
            url_obj.account = url_obj.loginnum
            url_obj.name = url_obj.name
            url_obj.school = url_obj.school
        }
        const fusion = {
            ...user,
            ...url_obj
        }
        if (to.name !== 'login' && !fusion.account) {
            router.replace({ name: 'login' })
            return false
        } else {
            return true
        }
    })
}

export default router