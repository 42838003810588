import { createApp } from 'vue'
import ElementPlus from 'element-plus'
import 'element-plus/dist/index.css'
import { createPinia } from 'pinia'
import App from './App.vue'
import router from './router'
import ajax from "@/ajax";

import '@/assets/css/base.scss'
import '@/assets/css/iconfont.css'

var app = createApp(App)

app.use(ElementPlus)
app.use(createPinia())
app.use(router)

app.config.globalProperties._ajax = ajax

//master

app.mount('#app')
