<template>
    <el-config-provider :locale="zhCn">
        <!-- 智能财务 -- 精英计划版 -->
        <router-view />
    </el-config-provider>
</template>
<script setup>
import {
    onMounted,
    getCurrentInstance,
} from 'vue'
const {
    // ctx,
    proxy
} = getCurrentInstance()
import zhCn from "element-plus/lib/locale/lang/zh-cn"
import { storeInit } from '@/store/index.js'
import { test } from '@/config/index';
const store = storeInit()

// mounted
onMounted(() => {
    // asideMenuDataRe()
    if (test) {
        store.user.ID = "114"
        store.user.account = "001"
        store.user.email = "yk@163.com"
        store.user.msg = "登录成功"
        store.user.name = "liu"
        store.user.school = "产品支持"
        store.user.telephone = "18640195989"
    }
    var url_obj = proxy._ajax.get_url_argument_obj()
    if (url_obj.loginnum) {
        store.user.ID = url_obj.loginnum
        store.user.account = url_obj.loginnum
        store.user.name = url_obj.name
        store.user.school = url_obj.school
        localStorage.user = JSON.stringify(store.user)
        var date = new Date()
        var m = date.getMonth() + 1
        var d = date.getDate()
        localStorage.date = date.getFullYear() + "/" + (m < 10 ? "0" + m : m) + "/" + (d < 10 ? "0" + d : d)
    }
    proxy._ajax.store = store
})
</script>