export const routes = [
    //
    {
        path: '/login',
        name: 'login',
        component: () =>
            import('@/views/user/login.vue')
    },
    /*{
        path: '/',
        name: 'board',
        component: () =>
            import('@/views/board/index.vue')
    },*/
    {
        path: '/',
        name: 'index',
        component: () =>
            import('@/views/board/index2.vue')
    },
    {
        path: '/home',
        name: 'home',
        component: () =>
            import('@/views/home.vue'),
        redirect: '/custom',
        children: [
            // 数据爬取
            {
                path: '/customCrawl',
                name: 'customCrawl',
                component: () =>
                    import('@/views/crawling/customCrawl_4_22.vue'),
                meta: {
                    title: '选项爬取',
                }
            },
            {
                path: '/crawScheme',
                name: 'crawScheme',
                component: () =>
                    import('@/views/crawling/crawScheme.vue'),
                meta: {
                    title: '爬取方案',
                }
            },
            {
                path: '/custom',
                name: 'custom',
                component: () =>
                    import('@/views/crawling/custom.vue'),
                meta: {
                    title: '选项爬取',
                }
            },
            // 智能检索
            {
                path: '/localSearch',
                name: 'localSearch',
                component: () =>
                    import('@/views/no-page.vue'),
                meta: {
                    title: '本地检索',
                }
            },
            {
                path: '/generalSearch',
                name: 'generalSearch',
                component: () =>
                    import('@/views/retrieval/generalSearch.vue'),
                meta: {
                    title: '通用检索',
                }
            },
            {
                path: '/generalSearch_key1',
                name: 'generalSearch_key1',
                component: () =>
                    import('@/views/retrieval/generalSearch.vue'),
                meta: {
                    title: '词频查询',
                }
            },
            {
                path: '/generalSearch_key2',
                name: 'generalSearch_key2',
                component: () =>
                    import('@/views/retrieval/generalSearch.vue'),
                meta: {
                    title: '句式检索',
                }
            },
            {
                path: '/generalSearch_key3',
                name: 'generalSearch_key3',
                component: () =>
                    import('@/views/retrieval/generalSearch.vue'),
                meta: {
                    title: '段落检索',
                }
            },
            {
                path: '/generalSearch_key4',
                name: 'generalSearch_key4',
                component: () =>
                    import('@/views/retrieval/generalSearch.vue'),
                meta: {
                    title: '相似内容',
                }
            },
            {
                path: '/generalSearch_key5',
                name: 'generalSearch_key5',
                component: () =>
                    import('@/views/retrieval/generalSearch.vue'),
                meta: {
                    title: '段落语义',
                }
            },
            {
                path: '/generalSearch_key6',
                name: 'generalSearch_key6',
                component: () =>
                    import('@/views/retrieval/generalSearch.vue'),
                meta: {
                    title: '段落字典',
                }
            },
            {
                path: '/generalSearch_key7',
                name: 'generalSearch_key7',
                component: () =>
                    import('@/views/retrieval/generalSearch.vue'),
                meta: {
                    title: '句义字典',
                }
            },
            {
                path: '/generalSearch_key8',
                name: 'generalSearch_key8',
                component: () =>
                    import('@/views/retrieval/generalSearch.vue'),
                meta: {
                    title: 'KPI字典',
                }
            },
            {
                path: '/generalSearch_key9',
                name: 'generalSearch_key9',
                component: () =>
                    import('@/views/retrieval/generalSearch.vue'),
                meta: {
                    title: '同义查询',
                }
            },
            {
                path: '/generalSearch_key10',
                name: 'generalSearch_key10',
                component: () =>
                    import('@/views/retrieval/generalSearch.vue'),
                meta: {
                    title: '01特征字典',
                }
            },
            {
                path: '/accountingAnalysis',
                name: 'accountingAnalysis',
                component: () =>
                    import('@/views/retrieval/accountingAnalysis.vue'),
                meta: {
                    title: '智能会计分析',
                }
            },
            {
                path: '/questionsAnswers',
                name: 'questionsAnswers',
                component: () =>
                    import('@/views/retrieval/questionsAnswers.vue'),
                meta: {
                    title: '智能专家问答',
                }
            },
            {
                path: '/informationAnalysis',
                name: 'informationAnalysis',
                component: () =>
                    import('@/views/retrieval/informationAnalysis.vue'),
                meta: {
                    title: '智能资讯分析',
                }
            },
            {
                path: '/financialDecision',
                name: 'financialDecision',
                component: () =>
                    import('@/views/retrieval/financialDecision.vue'),
                meta: {
                    title: '智能财务决策',
                }
            },
            {
                path: '/intelSearch',
                name: 'intelSearch',
                component: () =>
                    import('@/views/retrieval/intelSearch.vue'),
                meta: {
                    title: '智能检索',
                }
            },
            {
                path: '/knowGraph',
                name: 'knowGraph',
                component: () =>
                    import('@/views/no-page.vue'),
                meta: {
                    title: '知识图谱',
                }
            },
            // 财经字典
            {
                path: '/dictionary',
                name: 'dictionary',
                component: () =>
                    import('@/views/dictionary/index.vue'),
                meta: {
                    title: '财经字典应用',
                }
            },
            {
                path: '/dictionary_key1',
                name: 'dictionary_key1',
                component: () =>
                    import('@/views/dictionary/index.vue'),
                meta: {
                    title: '年报格式准则',
                }
            },
            {
                path: '/dictionary_key2',
                name: 'dictionary_key2',
                component: () =>
                    import('@/views/dictionary/index.vue'),
                meta: {
                    title: '会计准则',
                }
            },
            {
                path: '/dictionary_key3',
                name: 'dictionary_key3',
                component: () =>
                    import('@/views/dictionary/index.vue'),
                meta: {
                    title: '内部控制',
                }
            },
            {
                path: '/dictionary_key4',
                name: 'dictionary_key4',
                component: () =>
                    import('@/views/dictionary/index.vue'),
                meta: {
                    title: '战略与风险',
                }
            },
            {
                path: '/dictionary_key5',
                name: 'dictionary_key5',
                component: () =>
                    import('@/views/dictionary/index.vue'),
                meta: {
                    title: '经营管理',
                }
            },
            {
                path: '/dictionary_key6',
                name: 'dictionary_key6',
                component: () =>
                    import('@/views/dictionary/index.vue'),
                meta: {
                    title: '公司治理',
                }
            },
            {
                path: '/dictionary_key7',
                name: 'dictionary_key7',
                component: () =>
                    import('@/views/dictionary/index.vue'),
                meta: {
                    title: '投融资',
                }
            },
            {
                path: '/dictionary_key8',
                name: 'dictionary_key8',
                component: () =>
                    import('@/views/dictionary/index.vue'),
                meta: {
                    title: '资本管理',
                }
            },
            {
                path: '/dictionary_key9',
                name: 'dictionary_key9',
                component: () =>
                    import('@/views/dictionary/index.vue'),
                meta: {
                    title: '信息技术',
                }
            },
            {
                path: '/dictionary_key10',
                name: 'dictionary_key10',
                component: () =>
                    import('@/views/dictionary/index.vue'),
                meta: {
                    title: '政策环境',
                }
            },
            {
                path: '/dictionary_key11',
                name: 'dictionary_key11',
                component: () =>
                    import('@/views/dictionary/index.vue'),
                meta: {
                    title: '社会责任',
                }
            },
            {
                path: '/dictionary_key12',
                name: 'dictionary_key12',
                component: () =>
                    import('@/views/dictionary/index.vue'),
                meta: {
                    title: '会计方法字典',
                }
            },
            {
                path: '/dictionary_key13',
                name: 'dictionary_key13',
                component: () =>
                    import('@/views/dictionary/index.vue'),
                meta: {
                    title: 'ESG字典',
                }
            },
            {
                path: '/dictionary_key14',
                name: 'dictionary_key14',
                component: () =>
                    import('@/views/dictionary/index.vue'),
                meta: {
                    title: '经营模式字典',
                }
            },
            {
                path: '/dictionary_key15',
                name: 'dictionary_key15',
                component: () =>
                    import('@/views/dictionary/index.vue'),
                meta: {
                    title: '企业战略字典',
                }
            },
            {
                path: '/dictionary_key16',
                name: 'dictionary_key16',
                component: () =>
                    import('@/views/dictionary/index.vue'),
                meta: {
                    title: '公司治理字典',
                }
            },
            {
                path: '/dictionary_key17',
                name: 'dictionary_key17',
                component: () =>
                    import('@/views/dictionary/index.vue'),
                meta: {
                    title: '公司内控字典',
                }
            },
            {
                path: '/dictionary_key18',
                name: 'dictionary_key18',
                component: () =>
                    import('@/views/dictionary/index.vue'),
                meta: {
                    title: '年报信息格式字典',
                }
            },
            {
                path: '/dictionary_key19',
                name: 'dictionary_key19',
                component: () =>
                    import('@/views/dictionary/index.vue'),
                meta: {
                    title: '情感字典',
                }
            },
            {
                path: '/dictionary_key20',
                name: 'dictionary_key20',
                component: () =>
                    import('@/views/dictionary/index.vue'),
                meta: {
                    title: '语调字典',
                }
            },








            // 数据可视化
            {
                path: '/businessIntelligenceVisualization',
                name: 'businessIntelligenceVisualization',
                component: () =>
                    import('@/views/businessIntelligenceVisualization/index.vue'),
                meta: {
                    title: '智能财务分析',
                }
            },
            {
                path: '/businessIntelligenceVisualization_key1',
                name: 'businessIntelligenceVisualization_key1',
                component: () =>
                    import('@/views/businessIntelligenceVisualization/index.vue'),
                meta: {
                    title: '智能财务分析',
                }
            },
            {
                path: '/businessIntelligenceVisualization_key2',
                name: 'businessIntelligenceVisualization_key2',
                component: () =>
                    import('@/views/businessIntelligenceVisualization/index.vue'),
                meta: {
                    title: '智能业绩评价',
                }
            },
            {
                path: '/businessIntelligenceVisualization_key3',
                name: 'businessIntelligenceVisualization_key3',
                component: () =>
                    import('@/views/businessIntelligenceVisualization/index.vue'),
                meta: {
                    title: '智能财务审计',
                }
            },
            {
                path: '/stock',
                name: 'stock',
                component: () =>
                    import('@/views/iframe-page.vue'),
                meta: {
                    title: '存货',
                }
            },
            {
                path: '/fixed',
                name: 'fixed',
                component: () =>
                    import('@/views/iframe-page.vue'),
                meta: {
                    title: '固定资产',
                }
            },
            {
                path: '/intAssets',
                name: 'intAssets',
                component: () =>
                    import('@/views/iframe-page.vue'),
                meta: {
                    title: '无形资产',
                }
            },
            {
                path: '/assetImpairment',
                name: 'assetImpairment',
                component: () =>
                    import('@/views/iframe-page.vue'),
                meta: {
                    title: '资产减值',
                }
            },
            {
                path: '/investmentEstate',
                name: 'investmentEstate',
                component: () =>
                    import('@/views/iframe-page.vue'),
                meta: {
                    title: '投资性房地产',
                }
            },
            {
                path: '/payables',
                name: 'payables',
                component: () =>
                    import('@/views/iframe-page.vue'),
                meta: {
                    title: '应付项目',
                }
            },
            {
                path: '/receivables',
                name: 'receivables',
                component: () =>
                    import('@/views/iframe-page.vue'),
                meta: {
                    title: '应收项目',
                }
            },
            {
                path: '/equity',
                name: 'equity',
                component: () =>
                    import('@/views/iframe-page.vue'),
                meta: {
                    title: '股本',
                }
            },
            {
                path: '/loan',
                name: 'loan',
                component: () =>
                    import('@/views/iframe-page.vue'),
                meta: {
                    title: '借款',
                }
            },
            {
                path: '/govGrants',
                name: 'govGrants',
                component: () =>
                    import('@/views/iframe-page.vue'),
                meta: {
                    title: '政府补助',
                }
            },
            {
                path: '/nonRecurringLoss',
                name: 'nonRecurringLoss',
                component: () =>
                    import('@/views/iframe-page.vue'),
                meta: {
                    title: '非经常性损益',
                }
            },
            {
                path: '/longDeferredExpenses',
                name: 'longDeferredExpenses',
                component: () =>
                    import('@/views/iframe-page.vue'),
                meta: {
                    title: '长期待摊费用',
                }
            },
            {
                path: '/longInvestment',
                name: 'longInvestment',
                component: () =>
                    import('@/views/iframe-page.vue'),
                meta: {
                    title: '长期股权投资',
                }
            },
            {
                path: '/undistrProfit',
                name: 'undistrProfit',
                component: () =>
                    import('@/views/iframe-page.vue'),
                meta: {
                    title: '未分配利润',
                }
            },
            {
                path: '/income',
                name: 'income',
                component: () =>
                    import('@/views/iframe-page.vue'),
                meta: {
                    title: '收入',
                }
            },
            {
                path: '/periodCost',
                name: 'periodCost',
                component: () =>
                    import('@/views/iframe-page.vue'),
                meta: {
                    title: '期间费用',
                }
            },
            {
                path: '/taxesSurcharges',
                name: 'taxesSurcharges',
                component: () =>
                    import('@/views/iframe-page.vue'),
                meta: {
                    title: '税金及附加',
                }
            },
            {
                path: '/macroAnaly',
                name: 'macroAnaly',
                component: () =>
                    import('@/views/iframe-page.vue'),
                meta: {
                    title: '宏观统计',
                }
            },
            {
                path: '/industryAnaly',
                name: 'industryAnaly',
                component: () =>
                    import('@/views/iframe-page.vue'),
                meta: {
                    title: '行业分析',
                }
            },
            {
                path: '/financialAna',
                name: 'financialAna',
                component: () =>
                    import('@/views/iframe-page.vue'),
                meta: {
                    title: '财务分析',
                }
            },
            {
                path: '/investIna',
                name: 'investIna',
                component: () =>
                    import('@/views/iframe-page.vue'),
                meta: {
                    title: '投融资',
                }
            },
            {
                path: '/strategicAnaly',
                name: 'strategicAnaly',
                component: () =>
                    import('@/views/iframe-page.vue'),
                meta: {
                    title: '战略分析',
                }
            },
            {
                path: '/prospectAnaly',
                name: 'prospectAnaly',
                component: () =>
                    import('@/views/iframe-page.vue'),
                meta: {
                    title: '前景分析',
                }
            },
            {
                path: '/eveAnal',
                name: 'eveAnal',
                component: () =>
                    import('@/views/iframe-page.vue'),
                meta: {
                    title: '事件分析',
                }
            },
            {
                path: '/eveSearch',
                name: 'eveSearch',
                component: () =>
                    import('@/views/iframe-page.vue'),
                meta: {
                    title: '事件查询',
                }
            },
            {
                path: '/stockInv',
                name: 'stockInv',
                component: () =>
                    import('@/views/iframe-page.vue'),
                meta: {
                    title: '股票投资',
                }
            },
            {
                path: '/auditMatters',
                name: 'auditMatters',
                component: () =>
                    import('@/views/iframe-page.vue'),
                meta: {
                    title: '关键审计事项',
                }
            },
            {
                path: '/finFraud',
                name: 'finFraud',
                component: () =>
                    import('@/views/iframe-page.vue'),
                meta: {
                    title: '财务舞弊',
                }
            },
            {
                path: '/resAudit',
                name: 'resAudit',
                component: () =>
                    import('@/views/iframe-page.vue'),
                meta: {
                    title: '经责审计',
                }
            },
            {
                path: '/finCustom',
                name: 'finCustom',
                component: () =>
                    import('@/views/no-page.vue'),
                meta: {
                    title: '财务指标定制',
                }
            },
            {
                path: '/fiscal_policy',
                name: 'fiscal_policy',
                component: () =>
                    import('@/views/iframe-page.vue'),
                meta: {
                    title: '财政政策',
                }
            },
            {
                path: '/monetary_policy',
                name: 'monetary_policy',
                component: () =>
                    import('@/views/iframe-page.vue'),
                meta: {
                    title: '货币政策',
                }
            },
            {
                path: '/Intonation_analysis_of_financial_information',
                name: 'Intonation_analysis_of_financial_information',
                component: () =>
                    import('@/views/iframe-page.vue'),
                meta: {
                    title: '财经资讯语调分析',
                }
            },
            {
                path: '/Financial_keyword_cloud',
                name: 'Financial_keyword_cloud',
                component: () =>
                    import('@/views/iframe-page.vue'),
                meta: {
                    title: '财经关键词云',
                }
            },
            {
                path: '/Industry_operation_related_effects',
                name: 'Industry_operation_related_effects',
                component: () =>
                    import('@/views/iframe-page.vue'),
                meta: {
                    title: '行业经营相关效应',
                }
            },
            {
                path: '/Industry_Lerner_Index',
                name: 'Industry_Lerner_Index',
                component: () =>
                    import('@/views/iframe-page.vue'),
                meta: {
                    title: '行业勒纳指数',
                }
            },
            {
                path: '/Balance_sheet_analysis',
                name: 'Balance_sheet_analysis',
                component: () =>
                    import('@/views/iframe-page.vue'),
                meta: {
                    title: '资产负债表分析',
                }
            },
            {
                path: '/Income_statement_analysis',
                name: 'Income_statement_analysis',
                component: () =>
                    import('@/views/iframe-page.vue'),
                meta: {
                    title: '利润表分析',
                }
            },
            {
                path: '/Analysis_of_cash_flow_statement',
                name: 'Analysis_of_cash_flow_statement',
                component: () =>
                    import('@/views/iframe-page.vue'),
                meta: {
                    title: '现金流量表分析',
                }
            },
            {
                path: '/Penetrating_financial_report_analysis',
                name: 'Penetrating_financial_report_analysis',
                component: () =>
                    import('@/views/iframe-page.vue'),
                meta: {
                    title: '穿透式财报分析',
                }
            },
            {
                path: '/Financial_index_analysis',
                name: 'Financial_index_analysis',
                component: () =>
                    import('@/views/iframe-page.vue'),
                meta: {
                    title: '财务指标分析',
                }
            },
            {
                path: '/Accounting_macro_value_index',
                name: 'Accounting_macro_value_index',
                component: () =>
                    import('@/views/iframe-page.vue'),
                meta: {
                    title: '会计宏观价值指数',
                }
            },
            {
                path: '/Comprehensive_evaluation_of_business_performance',
                name: 'Comprehensive_evaluation_of_business_performance',
                component: () =>
                    import('@/views/iframe-page.vue'),
                meta: {
                    title: '经营业绩综合评价',
                }
            },
            {
                path: '/DuPont_analysis',
                name: 'DuPont_analysis',
                component: () =>
                    import('@/views/iframe-page.vue'),
                meta: {
                    title: '杜邦分析',
                }
            },
            {
                path: '/Optional_accounting_policy_application',
                name: 'Optional_accounting_policy_application',
                component: () =>
                    import('@/views/iframe-page.vue'),
                meta: {
                    title: '可选择会计政策应用',
                }
            },
            {
                path: '/Application_of_important_accounting_estimates',
                name: 'Application_of_important_accounting_estimates',
                component: () =>
                    import('@/views/iframe-page.vue'),
                meta: {
                    title: '重要会计估计应用',
                }
            },
            {
                path: '/Company_accounting_method',
                name: 'Company_accounting_method',
                component: () =>
                    import('@/views/iframe-page.vue'),
                meta: {
                    title: '公司会计方法',
                }
            },
            {
                path: '/Income_fraud',
                name: 'Income_fraud',
                component: () =>
                    import('@/views/iframe-page.vue'),
                meta: {
                    title: '收入舞弊',
                }
            },
            {
                path: '/Asset_fraud',
                name: 'Asset_fraud',
                component: () =>
                    import('@/views/iframe-page.vue'),
                meta: {
                    title: '资产舞弊',
                }
            },
            {
                path: '/Expense_fraud',
                name: 'Expense_fraud',
                component: () =>
                    import('@/views/iframe-page.vue'),
                meta: {
                    title: '费用舞弊',
                }
            },
            {
                path: '/Strategic_choice_and_evaluation',
                name: 'Strategic_choice_and_evaluation',
                component: () =>
                    import('@/views/iframe-page.vue'),
                meta: {
                    title: '战略选择与评价',
                }
            },
            {
                path: '/Risk_identification_and_evaluation',
                name: 'Risk_identification_and_evaluation',
                component: () =>
                    import('@/views/iframe-page.vue'),
                meta: {
                    title: '风险识别与评价',
                }
            },
            {
                path: '/Performance_driven_and_evaluation',
                name: 'Performance_driven_and_evaluation',
                component: () =>
                    import('@/views/iframe-page.vue'),
                meta: {
                    title: '业绩驱动与评价',
                }
            },
            {
                path: '/Core_competitiveness_analysis',
                name: 'Core_competitiveness_analysis',
                component: () =>
                    import('@/views/iframe-page.vue'),
                meta: {
                    title: '核心竞争力分析',
                }
            },
            {
                path: '/Industry_development_and_evaluation',
                name: 'Industry_development_and_evaluation',
                component: () =>
                    import('@/views/iframe-page.vue'),
                meta: {
                    title: '行业发展与评价',
                }
            },
            {
                path: '/Management_decision',
                name: 'Management_decision',
                component: () =>
                    import('@/views/iframe-page.vue'),
                meta: {
                    title: '经营管理决策',
                }
            },
            {
                path: '/Investment_expansion_decision',
                name: 'Investment_expansion_decision',
                component: () =>
                    import('@/views/iframe-page.vue'),
                meta: {
                    title: '投资扩张决策',
                }
            },
            {
                path: '/Cost_leadership_strategy',
                name: 'Cost_leadership_strategy',
                component: () =>
                    import('@/views/iframe-page.vue'),
                meta: {
                    title: '成本领先战略',
                }
            },
            {
                path: '/Capital_structure_management',
                name: 'Capital_structure_management',
                component: () =>
                    import('@/views/iframe-page.vue'),
                meta: {
                    title: '资本结构管理',
                }
            },
            {
                path: '/Financial_statement_forecast',
                name: 'Financial_statement_forecast',
                component: () =>
                    import('@/views/iframe-page.vue'),
                meta: {
                    title: '财务报表预测',
                }
            },
            {
                path: '/Resolution_of_the_board_of_directors',
                name: 'Resolution_of_the_board_of_directors',
                component: () =>
                    import('@/views/iframe-page.vue'),
                meta: {
                    title: '董事会决议',
                }
            },
            {
                path: '/Employee_compensation',
                name: 'Employee_compensation',
                component: () =>
                    import('@/views/iframe-page.vue'),
                meta: {
                    title: '职工薪酬',
                }
            },
            {
                path: '/Industrial_economy_keyword_cloud',
                name: 'Industrial_economy_keyword_cloud',
                component: () =>
                    import('@/views/iframe-page.vue'),
                meta: {
                    title: '产业经济关键词云',
                }
            },
            {
                path: '/Tone_analysis_of_industrial_economy',
                name: 'Tone_analysis_of_industrial_economy',
                component: () =>
                    import('@/views/iframe-page.vue'),
                meta: {
                    title: '产业经济语调分析',
                }
            },
            // 方案共享
            {
                path: '/planManage',
                name: 'planManage',
                component: () =>
                    import('@/views/plan/planManage.vue'),
                meta: {
                    title: '方案管理',
                }
            },
            {
                path: '/proCentre',
                name: 'proCentre',
                component: () =>
                    import('@/views/no-page.vue'),
                meta: {
                    title: '方案中心',
                }
            },
            {
                path: '/schoolPro',
                name: 'schoolPro',
                component: () =>
                    import('@/views/no-page.vue'),
                meta: {
                    title: '校内方案',
                }
            },
            // 标注工具
            /*{
                path: '/lawOfCausality',
                name: 'lawOfCausality',
                component: () =>
                    import ('@/views/iframe-page.vue'),
                meta: {
                    title: '因果事件标注',
                }
            },*/
            {
                path: '/lawOfCausality',
                name: 'lawOfCausality',
                component: () =>
                    import('@/views/lawOfCausality/index.jsx'),
                meta: {
                    title: '因果标注工具',
                }
            },
            {
                path: '/usersetting',
                name: 'usersetting',
                component: () =>
                    import('@/views/user/usersetting.vue'),
                meta: {
                    title: '用户设置',
                }
            },
            // 智能科研助手
            {
                path: '/intelligentInformation',
                name: 'intelligentInformation',
                component: () =>
                    import('@/views/researchAssistant/intelligentInformation.vue'),
                meta: {
                    title: '智能资讯',
                }
            },
            {
                path: '/qualityTesting',
                name: 'qualityTesting',
                component: () =>
                    import('@/views/inspect/index.vue'),
                meta: {
                    title: '质量检查',
                }
            },
            {
                path: '/intelligentTopicSelection',
                name: 'intelligentTopicSelection',
                component: () =>
                    import('@/views/researchAssistant/intelligentTopicSelection.vue'),
                meta: {
                    title: '智能选题',
                }
            },
            {
                path: '/argumentativeAnalysis',
                name: 'argumentativeAnalysis',
                component: () =>
                    import('@/views/researchAssistant/argumentativeAnalysis.vue'),
                meta: {
                    title: '智能实证',
                }
            },
            // 企业内部控制研究
            {
                path: 'DList',
                name: 'DList',
                component: () =>
                    import('@/views/controlStudy/DList.vue'),
                meta: {
                    title: '指标D库',
                }
            },
            {
                path: 'TList',
                name: 'TList',
                component: () =>
                    import('@/views/controlStudy/TList.vue'),
                meta: {
                    title: '指标T库',
                }
            },
            {
                path: 'paperSemonstration',
                name: 'paperSemonstration',
                component: () =>
                    import('@/views/controlStudy/paperSemonstration.vue'),
                meta: {
                    title: '论文实证',
                }
            },
            {
                path: 'paperSemonstrationAdd',
                name: 'paperSemonstrationAdd',
                component: () =>
                    import('@/views/controlStudy/paperSemonstrationAdd.vue'),
                meta: {
                    title: '论文实证',
                }
            },
            {
                path: 'AIe2_paperSemonstration',
                name: 'AIe2_paperSemonstration',
                component: () =>
                    import('@/views/controlStudy_AIe2/paperSemonstration.vue'),
                meta: {
                    title: 'AIe2_论文实证',
                }
            },
            {
                path: 'AIe2_paperSemonstrationAdd',
                name: 'AIe2_paperSemonstrationAdd',
                component: () =>
                    import('@/views/controlStudy_AIe2/paperSemonstrationAdd.vue'),
                meta: {
                    title: 'AIe2_论文实证',
                }
            },
            


        ]
    },
    {
        path: '/only',
        name: 'only',
        component: () =>
            import('@/views/only.vue'),
        children: [
            //
            /*{
                path: '/businessIntelligenceVisualization_only',
                name: 'businessIntelligenceVisualization_only',
                component: () =>
                    import('@/views/businessIntelligenceVisualization/index.vue'),
                meta: {
                    title: '智能财务分析',
                }
            },*/
            {
                path: '/businessIntelligenceVisualization_key1_only',
                name: 'businessIntelligenceVisualization_key1_only',
                component: () =>
                    import('@/views/businessIntelligenceVisualization/index.vue'),
                meta: {
                    title: '智能财务分析',
                }
            },
            {
                path: '/businessIntelligenceVisualization_key2_only',
                name: 'businessIntelligenceVisualization_key2_only',
                component: () =>
                    import('@/views/businessIntelligenceVisualization/index.vue'),
                meta: {
                    title: '智能业绩评价',
                }
            },
            {
                path: '/businessIntelligenceVisualization_key3_only',
                name: 'businessIntelligenceVisualization_key3_only',
                component: () =>
                    import('@/views/businessIntelligenceVisualization/index.vue'),
                meta: {
                    title: '智能财务审计',
                }
            },
            // {
            //     path: '/accountingAnalysis_only',
            //     name: 'accountingAnalysis_only',
            //     component: () =>
            //         import('@/views/retrieval/accountingAnalysis.vue'),
            //     meta: {
            //         title: '智能会计分析',
            //     }
            // },
            {
                path: '/businessIntelligenceVisualization_key5_only',
                name: 'businessIntelligenceVisualization_key5_only',
                component: () =>
                    import('@/views/businessIntelligenceVisualization/index.vue'),
                meta: {
                    title: '智能会计分析',
                }
            },
            {
                path: '/financialDecision_only',
                name: 'financialDecision_only',
                component: () =>
                    import('@/views/retrieval/financialDecision.vue'),
                meta: {
                    title: '智能财务决策',
                }
            },
            {
                path: '/informationAnalysis_only',
                name: 'informationAnalysis_only',
                component: () =>
                    import('@/views/retrieval/informationAnalysis.vue'),
                meta: {
                    title: '智能资讯分析',
                }
            },
            {
                path: '/generalSearch_only',
                name: 'generalSearch_only',
                component: () =>
                    import('@/views/retrieval/generalSearch.vue'),
                meta: {
                    title: '通用检索',
                }
            },
            {
                path: '/usersetting_only',
                name: 'usersetting_only',
                component: () =>
                    import('@/views/user/usersetting.vue'),
                meta: {
                    title: '用户设置',
                }
            },
            {
                path: 'AIe2_paperSemonstration_only',
                name: 'AIe2_paperSemonstration_only',
                component: () =>
                    import('@/views/controlStudy_AIe2/paperSemonstration.vue'),
                meta: {
                    title: 'AIe2_论文实证',
                }
            },
            {
                path: 'AIe2_paperSemonstrationAdd_only',
                name: 'AIe2_paperSemonstrationAdd_only',
                component: () =>
                    import('@/views/controlStudy_AIe2/paperSemonstrationAdd.vue'),
                meta: {
                    title: 'AIe2_论文实证',
                }
            },
            {
                // path: '/questionsAnswers_only',
                // name: 'questionsAnswers_only',
                path: '/businessIntelligenceVisualization_key4_only',
                name: 'businessIntelligenceVisualization_key4_only',
                component: () =>
                    // import('@/views/retrieval/questionsAnswers.vue'),
                    import('@/views/businessIntelligenceVisualization/index.vue'),
                meta: {
                    title: '智能专家问答',
                }
            },
            {
                path: '/inspect_only',
                name: 'inspect_only',
                component: () =>
                    import('@/views/inspect/index.vue'),
                meta: {
                    title: '智能专家问答',
                }
            },
            {
                path: '/lawOfCausality_only',
                name: 'lawOfCausality_only',
                component: () =>
                    import('@/views/lawOfCausality/index.jsx'),
                meta: {
                    title: '因果标注工具',
                }
            },
            {
                path: '/instructions',
                name: 'instructions',
                component: () =>
                    import('@/views/inspect/Instructions.vue'),
                meta: {
                    title: '智能专家问答',
                }
            },
            {
                path: '/guestbook',
                name: 'guestbook',
                component: () =>
                    import('@/views/inspect/guestbook.vue'),
                meta: {
                    title: '智能专家问答',
                }
            },
            {
                path: '/generalSearch_key1_only',
                name: 'generalSearch_key1_only',
                component: () =>
                    import('@/views/retrieval/generalSearch.vue'),
                meta: {
                    title: '词频查询',
                }
            },
            {
                path: '/generalSearch_key2_only',
                name: 'generalSearch_key2_only',
                component: () =>
                    import('@/views/retrieval/generalSearch.vue'),
                meta: {
                    title: '句式检索',
                }
            },
            {
                path: '/generalSearch_key3_only',
                name: 'generalSearch_key3_only',
                component: () =>
                    import('@/views/retrieval/generalSearch.vue'),
                meta: {
                    title: '段落检索',
                }
            },
            {
                path: '/generalSearch_key4_only',
                name: 'generalSearch_key4_only',
                component: () =>
                    import('@/views/retrieval/generalSearch.vue'),
                meta: {
                    title: '相似内容',
                }
            },
            {
                path: '/generalSearch_key5_only',
                name: 'generalSearch_key5_only',
                component: () =>
                    import('@/views/retrieval/generalSearch.vue'),
                meta: {
                    title: '段落语义',
                }
            },
            {
                path: '/generalSearch_key6_only',
                name: 'generalSearch_key6_only',
                component: () =>
                    import('@/views/retrieval/generalSearch.vue'),
                meta: {
                    title: '段落字典',
                }
            },
            {
                path: '/generalSearch_key7_only',
                name: 'generalSearch_key7_only',
                component: () =>
                    import('@/views/retrieval/generalSearch.vue'),
                meta: {
                    title: '句义字典',
                }
            },
            {
                path: '/generalSearch_key8_only',
                name: 'generalSearch_key8_only',
                component: () =>
                    import('@/views/retrieval/generalSearch.vue'),
                meta: {
                    title: 'KPI字典',
                }
            },
            {
                path: '/generalSearch_key9_only',
                name: 'generalSearch_key9_only',
                component: () =>
                    import('@/views/retrieval/generalSearch.vue'),
                meta: {
                    title: '同义查询',
                }
            },
            {
                path: '/generalSearch_key10_only',
                name: 'generalSearch_key10_only',
                component: () =>
                    import('@/views/retrieval/generalSearch.vue'),
                meta: {
                    title: '01特征字典',
                }
            },
        ]
    },

    {
        path: '/test',
        name: 'test',
        component: () =>
            import('@/views/test.vue')
    },
    /*{
      //常用写法,通过正则表达式,如果路由不匹配去这个404组件
      path: '/:pathMatch(.*)*',
      name: 'not-found',
      component: () => import('../view/home/Home.jsx')
    },*/
]