import axios from './axios.js';
import Qs from 'qs';
// import { Dialog } from 'vant';
import { ElMessage } from 'element-plus'
import { test } from '@/config/index';

var base_url = "https://mpacc.pub"
var base_url2 = "http://106.12.82.74:8926/"
var powerbi = "//app.powerbi.cn/"
var base_url_53 = 'http://1.13.246.53:8081'
if (test) {
    // console.log("开发环境")
    // base_url = config_url || "http://119.45.235.140:3191" 
    base_url = config_url || "http://192.168.1.125:9000" 
    // base_url = "http://192.168.2.7:9000" 
    base_url_53 = "http://1.13.246.53:8081"
} else {
    // console.log("生产环境")
    // base_url = config_url || "http://82.156.85.106:80"
    // base_url = config_url || "http://1.13.246.53:8081" // 精英计划
    // base_url = config_url || "http://1.13.246.53:8080" 
    // base_url = config_url || "http://119.45.235.140:3196" 
    base_url = config_url || "https://mpacc.pub" 
    // base_url2 = config_url2 || "http://82.156.85.106:8081"
    base_url_53 = "http://1.13.246.53:8081"
}

var url = {
    echarts: "/static/echarts/index.html?x=" + Math.random(),
    nav: base_url + "",
    login: base_url + "/login/",
    add: base_url + "/add/",
    selectCJZD: base_url + "/F_Science/selectCJZD/",
    Select_CJZD_PlanInfo: base_url + "/F_Science/Select_CJZD_PlanInfo/",
    Select_CJZD_InputInfo: base_url + "/F_Science/Select_CJZD_InputInfo/",
    Select_CJZD_Company: base_url + "/F_Science/Select_CJZD_Company/",
    searchCPY: base_url + "/searchCPY/",
    selectCPY: base_url + "/selectCPY/",
    selectCWZS: base_url + "/selectCWZS/",
    Select_ReportData: base_url + "/Select_ReportData/",
    selectggCPY: base_url + "/selectggCPY/",
    selJS: base_url + "/selJS/",
    selectplancipin: base_url + "/selectplancipin/",
    getcpcx: base_url + "/getcpcx/",
    insertplancipin: base_url + "/insertplancipin/",
    selectplanjsjs: base_url + "/selectplanjsjs/",
    getjsjs: base_url + "/getjsjs/",
    insertplanjsjs: base_url + "/insertplanjsjs/",
    selectplandlyy: base_url + "/selectplandlyy/",
    getdlyy: base_url + "/getdlyy/",
    insertplandlyy: base_url + "/insertplandlyy/",
    selectplanxsd: base_url + "/selectplanxsd/",
    getdlyy_zd: base_url + "/getdlyy_zd/",
    insertplandlyy_zd: base_url + "/insertplandlyy_zd/",
    selectplandlyy_zd: base_url + "/selectplandlyy_zd/",
    getcharacteristic: base_url + "/getcharacteristic/",
    insertplancharacteristic: base_url + "/insertplancharacteristic/",
    selectplancharacteristic: base_url + "/selectplancharacteristic/",
    getxsnr: base_url + "/getxsnr/",
    insertplanxsnr: base_url + "/insertplanxsnr/",
    selectplanzjzd: base_url + "/selectplanzjzd/",
    getzjzd: base_url + "/getzjzd/",
    insertplanzjzd: base_url + "/insertplanzjzd/",
    selectplanCPY: base_url + "/selectplanCPY/",
    delJS: base_url + "/delJS/",
    Search: base_url + "/Search/",
    createsqlPC: base_url + "/createsqlPC/",
    savePlanPC: base_url + "/savePlanPC/",
    selPC: base_url + "/selPC/",
    PCsearch: base_url + "/PCsearch/",
    delPC: base_url + "/delPC/",
    getgjz: base_url + "/getgjz/",
    getzhuti: base_url + "/getzhuti/",
    getzlzd: base_url + "/getzlzd/",
    getfxzd: base_url + "/getfxzd/",
    getqgsb: base_url + "/getqgsb/",
    getydsb: base_url + "/getydsb/",
    reuser: base_url + "/reuser/",
    repwd: base_url + "/repwd/",
    getC: base_url + "/F_Science/getC/",
    getV: base_url + "/F_Science/getV/",
    getS: base_url + "/F_Science/getS/",
    insertrecord: base_url + "/insertrecord/",
    down_file: base_url + "/F_Science/down_file/",
    getThirdType: base_url + "/F_Science/getThirdType/",
    getBondType: base_url + "/F_Science/getBondType/",
    Select_Table_ThirdInfo: base_url + "/Select_Table_ThirdInfo/",
    Select_Table_FundInfo: base_url + "/Select_Table_FundInfo/",
    Select_Table_BondInfo: base_url + "/Select_Table_BondInfo/",
    gettycx_ltp: base_url + "/gettycx_ltp/",
    gettycx_synonyms: base_url + "/gettycx_synonyms/",
    getzjzd_kpi: base_url + "/getzjzd_kpi/",
    insertplanzjzd_kpi: base_url + "/insertplanzjzd_kpi/",
    selectplanzjzd_kpi: base_url + "/selectplanzjzd_kpi/",

    uploadfile: base_url + "/api/uploadfile/",
    uploadfile_custom_data: base_url + "/api/uploadfile_custom_data/",
    downloadfile: base_url + "/api/downloadfile/",
    zdpp: base_url_53 + "/api/zdpp/",
    match: base_url + "/api/match/",
    getzb: base_url + "/api/getzb/",
    get_zb: base_url + "/api/get_zb/",
    run: base_url + "/api/run/",
    runfinancial: base_url + "/api/runfinancial/",
    Save_ZbPlan: base_url + "/api/Save_ZbPlan/",
    fengxianleibie: base_url + "/api/fengxianleibie/",
    yjjs: base_url + "/api/yjjs/",
    get_zwb: base_url + "/api/get_zwb/",
    yjjs_jsjs: base_url + "/api/yjjs_jsjs/",
    Select_FinanceData: base_url + "/api/Select_FinanceData/",
    getdljs: base_url + "/getdljs/",
    Top20: base_url + "/api/Top20/",
    Save_Table_JSRecord: base_url + "/api/Save_Table_JSRecord/",
    PerformanceEvaluation: base_url + "/api/PerformanceEvaluation/",
    Performance_Evaluation: base_url + "/api/Performance_Evaluation/",
    // Fraud: base_url + "/api/Fraud/",
    Fraud: base_url + "/api/Get_Fraud/",
    // Accounting_analysis: base_url + "/api/Accounting_analysis/",
    Accounting_analysis: base_url_53 + "/api/Accountinganalysis/",
    Funnel_analysis: base_url + "/api/Funnel_analysis/",
    Financial_decision: base_url + "/api/Financial_decision/",
    getKW: base_url + "/api/getKW/",
    Save_PersonalPlan: base_url + "/api/Save_PersonalPlan/",
    Select_PersonalPlan: base_url + "/api/Select_PersonalPlan/",
    week_month: base_url + "/api/week_month/",
    Intelligent_information: base_url + "/api/Intelligent_information/",
    Quality_testing: base_url + "/api/Quality_testing/",
    Testing_downloadfile: base_url + "/api/Testing_downloadfile/",
    Intelligent_Topic_Selection: base_url + "/api/Intelligent_Topic_Selection/",




    Intelligent_Demonstration: base_url + "/api/Intelligent_Demonstration/",
    Word_Cloud: base_url + "/api/Word_Cloud/",
    insert_Intelligent_Demonstration: base_url + "/api/insert_Intelligent_Demonstration/",
    select_Intelligent_Demonstration: base_url + "/api/select_Intelligent_Demonstration/",

    Financial_forecast: base_url + "/api/Financial_forecast/",
    Financialforecast: base_url + "/api/Financialforecast/",
    Select_Report_Data: base_url + "/api/Select_Report_Data/",
    Select_ReportData: base_url + "/api/Select_ReportData/",
    Select_zifuc: base_url + "/api/Select_zifuc/",
    Selectzifuc: base_url + "/api/Selectzifuc/",
    show_TLibrary: base_url + "/api/show_TLibrary/",
    Select_PlanInfoByPlanID: base_url + "/api/Select_PlanInfoByPlanID/",
    create_Word_Cloud: base_url + "/api/create_Word_Cloud/",

    // AnswerofIntelligence: base_url + "/api/AnswerofIntelligence/",
    AnswerofIntelligence: base_url + "/api/Answer_Intelligence/",




    SavePaper: base_url + "/AIe/SavePaper/",
    SaveFormula: base_url + "/AIe/SaveFormula/",
    showFormula: base_url + "/AIe/showFormula/",
    SaveHypothesis: base_url + "/AIe/SaveHypothesis/",
    showInfobyID: base_url + "/AIe/showInfobyID/",
    showPaper: base_url + "/AIe/showPaper/",
    SaveSample: base_url + "/AIe/SaveSample/",
    delPaper: base_url + "/AIe/delPaper/",
    showProblemSpace: base_url + "/AIe/showProblemSpace/",
    SaveVariable: base_url + "/AIe/SaveVariable/",
    SelectFactorByType: base_url + "/AIe/SelectFactorByType/",
    SelectFactorByName: base_url + "/AIe/SelectFactorByName/",
    showVariable: base_url + "/AIe/showVariable/",
    delVariable: base_url + "/AIe/delVariable/",
    showFormula_XY: base_url + "/AIe/showFormula_XY/",
    delHypothesis: base_url + "/AIe/delHypothesis/",
    delFormula: base_url + "/AIe/delFormula/",

    AIe2_SavePaper: base_url + "/AIe2/SavePaper/",
    AIe2_SaveFormula: base_url + "/AIe2/SaveFormula/",
    AIe2_showFormula: base_url + "/AIe2/showFormula/",
    AIe2_SaveHypothesis: base_url + "/AIe2/SaveHypothesis/",
    AIe2_showInfobyID: base_url + "/AIe2/showInfobyID/",
    AIe2_showPaper: base_url + "/AIe2/showPaper/",
    AIe2_SaveSample: base_url + "/AIe2/SaveSample/",
    AIe2_delPaper: base_url + "/AIe2/delPaper/",
    AIe2_showProblemSpace: base_url + "/AIe2/showProblemSpace/",
    AIe2_SaveVariable: base_url + "/AIe2/SaveVariable/",
    AIe2_SelectFactorByType: base_url + "/AIe2/SelectFactorByType/",
    AIe2_SelectFactorByName: base_url + "/AIe2/SelectFactorByName/",
    AIe2_showVariable: base_url + "/AIe2/showVariable/",
    AIe2_delVariable: base_url + "/AIe2/delVariable/",
    AIe2_showFormula_XY: base_url + "/AIe2/showFormula_XY/",
    AIe2_delHypothesis: base_url + "/AIe2/delHypothesis/",
    AIe2_delFormula: base_url + "/AIe2/delFormula/",
    AIe2_downloadfile: base_url + "/AIe2/downloadfile/",


    thesis_rule_inspect: base_url + "/AIe/thesis_rule_inspect/",
    thesis_specialization_inspect: base_url + "/AIe/thesis_specialization_inspect/",
    // get_txt_mark: base_url2 + "/AIe/get_txt_mark/",
    // update_txt_mark: base_url2 + "/AIe/update_txt_mark/",

    thesis_rule_inspect_uploadfile: base_url + "/AIe/thesis_rule_inspect_uploadfile/",
    thesis_rule_inspect_downloadfile: base_url + "/AIe/thesis_rule_inspect_downloadfile/",
    thesis_rule_inspect_problem_collection: base_url + "/AIe/thesis_rule_inspect_problem_collection/",


    AIGDataGet: base_url + "/Api/V1/M_AIQuestion/AIGDataGet",
    






































}
var obj = {
    powerbi,
    url,
    base_url_53,
    post(config) {
        var p_url = url[config.url] || config.url
        config.data = config.data || {}
        /*
            ID: "114"
            account: "001"
            email: "yk@163.com"
            msg: "登录成功"
            name: "liu"
            school: "产品支持"
            telephone: "18640195989"
        */
        if (config.url != 'login' && this.store.user.ID) {
            config.data.school = this.store.user.school
            config.data.account = this.store.user.account
            config.data.name = this.store.user.name
        }
        let params = new URLSearchParams();
        for (var i in config.data) {
            if (config.data[i] instanceof Array) {
                for (var j = 0; j < config.data[i].length; j++) {
                    if (config.data[i][j] instanceof Object) {
                        params.append(i, JSON.stringify(config.data[i][j]));
                    } else {
                        params.append(i, config.data[i][j]);
                    }
                };
            } else if (config.data[i] instanceof Object) {
                console.log("config.data[i]", i, config.data[i])
                params.append(i, JSON.stringify(config.data[i]));
            } else {
                params.append(i, config.data[i]);
            }
        };
        config.data = params;
        axios.post(p_url, config.data)
            .then(function(res) {
                if (res.data || res.data.toString) {
                    if (res.data.errCode) {
                        if (config.fail) {
                            config.fail(res.data)
                            return
                        }
                        ElMessage({
                            message: "提示:" + res.data.msg,
                            type: 'error',
                            duration: 5000,
                        })
                        /*Notification.error({
                            title: '提示',
                            message: res.data.msg
                        })*/
                        return
                    }
                    /*Notification.success({
                        title: '提示',
                        message: res.data.msg
                    })*/
                    config.success && config.success(res.data)
                    return
                }
                ElMessage({
                    message: "接口返回为空",
                    grouping: true,
                    type: 'error',
                })
            })
            .catch(function(error) {
                console.log("error", [error])
                var err_res = {
                    msg: "接口返回失败",
                }
                if (error.response) {
                    err_res.msg = {
                        "Internal Server Error": "内部服务器错误",
                    } [error.response.statusText] || (error.response.data && error.response.data.Message) || "接口返回失败"
                } else {
                    err_res.msg = "页面程序错误"

                }
                if (config.fail) {
                    config.fail({
                        errCode: 500,
                        msg: err_res.msg,
                    })
                } else {
                    ElMessage({
                        message: err_res.msg,
                        grouping: true,
                        type: 'error',
                    })
                    if (!error.response) {
                        console.error(error.stack)
                    }
                }
                config.error && config.error({
                    url: p_url
                })
            });
    },
    get(config) {
        var p_url = url[config.url] || config.url
        config.data = config.data || {}
        /*
            ID: "114"
            account: "001"
            email: "yk@163.com"
            msg: "登录成功"
            name: "liu"
            school: "产品支持"
            telephone: "18640195989"
        */
        /*if(this.store.user.ID){
            config.data.school = this.store.user.school
            config.data.account = this.store.user.account
            config.data.name = this.store.user.name
        }
        let params = new URLSearchParams();
        for (var i in config.data) {
            if (config.data[i] instanceof Array) {
                for (var j = 0; j < config.data[i].length; j++) {
                    params.append(i, config.data[i][j]);
                };
            } else if (config.data[i] instanceof Object) {
                params.append(i, JSON.stringify(config.data[i]));
            } else {
                params.append(i, config.data[i]);
            }
        };
        config.data = params;*/
        axios.get(p_url, config.data)
            .then(function(res) {
                if (res.data || res.data.toString) {
                    if (res.data.errCode) {
                        if (config.fail) {
                            config.fail(res.data)
                            return
                        }
                        ElMessage({
                            message: "提示:" + res.data.msg,
                            type: 'error',
                        })
                        /*Notification.error({
                            title: '提示',
                            message: res.data.msg
                        })*/
                        return
                    }
                    /*Notification.success({
                        title: '提示',
                        message: res.data.msg
                    })*/
                    config.success && config.success(res.data)
                    return
                }
                ElMessage({
                    message: "接口返回为空",
                    grouping: true,
                    type: 'error',
                })
            })
            .catch(function(error) {
                if (config.fail) {
                    config.fail({
                        errCode: 500,
                        msg: "接口返回失败"
                    })
                } else {
                    ElMessage({
                        message: "接口返回失败",
                        grouping: true,
                        type: 'error',
                    })
                }
                config.error && config.error({
                    url: p_url
                })
            });
    },
    url_obj() {
        return Qs.parse(location.search.replace("?", ""))
    },
    url_str(obj, _h = "") {
        return _h + Qs.stringify(obj)
    },
    get_url_argument(name) {
        var reg = new RegExp('(^|&)' + name + '=([^&]*)(&|$)')
        var r = decodeURI(window.location.search).substr(1).match(reg)
        if (r != null) return unescape(r[2])
        return null
    },
    get_url_argument_obj() {
        var obj = {}
        var reg = new RegExp('([^\\?|&]*)=([^&]*)', 'g')
        var r = decodeURI(window.location.search).match(reg) || []
        for (var i = 0; i < r.length; i++) {
            var t = r[i].split('=')
            obj[t[0]] = t[1]
        }
        return obj
    },
    url_passer(obj, key) {
        var _this_ = this
        var returnStr = ''
        switch (typeof obj) {
            case 'string':
            case 'number':
            case 'boolean':
                returnStr += '&' + key + '=' + obj
                break
            default:
                for (var i in obj) {
                    if (typeof obj[i] !== 'function') {
                        if (key && key !== '?' && key !== '&') {
                            returnStr += _this_.url_passer(obj[i], key + '[' + i + ']')
                        } else {
                            returnStr += _this_.url_passer(obj[i], i)
                        }
                    }
                }
                break
        }
        switch (key) {
            case '&':
                return returnStr.replace(/^./, '')
            case '?':
                returnStr = "?" + returnStr
        }
        /*if (key === '?') {
            return returnStr.replace(/^./, '?')
        } else if (key === '&') {
            return returnStr.replace(/^./, '')
        }*/
        return returnStr
    },
    column_prop_filter(item, first = [], not = []) {
        var arr = []
        var keys = Object.keys(item)
        // console.log("column_prop_filter",keys,first,not)
        arr = first.filter((a_item) => {
            return keys.indexOf(a_item) != -1
        })
        arr = arr.concat(keys.filter((a_item) => {
            return [...first, ...not].indexOf(a_item) == -1
        }))
        return arr
    },
    list_page_filter(list, page, page_size = 10) {
        var arr = [...list]
        arr = arr.slice((page - 1) * page_size, page * page_size)
        return arr
    },
    arr_a_filter_b(a, b = []) {
        return a.filter((a_item) => {
            return b.indexOf(a_item) == -1
        });
    },
    str_filter(val, _config, recursion) {
        _config = {
            s: 6,
            e: 4,
            ..._config
        }
        if (!val) {
            return val
        }
        return val.substring(0, _config.s) + "..." + val.substring(val.length - _config.e)
    },
    /*testFun(){
        console.log("testFun",this)
    }*/
}
// String.prototype._ajax = obj
// String.prototype._testFun = obj.testFun

export default obj